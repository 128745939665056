import { render, staticRenderFns } from "./ConfirmFactory.vue?vue&type=template&id=5329eab6&scoped=true"
import script from "./ConfirmFactory.vue?vue&type=script&lang=ts"
export * from "./ConfirmFactory.vue?vue&type=script&lang=ts"
import style0 from "./ConfirmFactory.vue?vue&type=style&index=0&id=5329eab6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5329eab6",
  null
  
)

export default component.exports