<template>
  <input
    v-bind="$attrs"
    v-model="inputValue"
    :type="type"
    :class="{ small }"
  >
</template>

<script>
import { computed, createComponent } from '@vue/composition-api'
export default createComponent({
  name: 'AppTextField',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup (props, context) {
    const inputValue = computed({
      get: () => props.value,
      set: (value) => context.emit('input', value)
    })

    return {
      inputValue
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
input {
  border: 1px solid $second-color;
  border-radius: 4px;
  padding: 10px 15px;
  outline: none;

  width: 100%;

  font-size: $form-font-size;

  &::placeholder {
    color: rgb(186, 186, 186);
  }

  &.small {
    font-size: 14px;
    padding: 3px 10px;
  }
}
</style>
